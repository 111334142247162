@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "IcebergRegular";  
  src: local("IcebergRegular"),
    url("../assets/fonts/Iceberg-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: IcebergRegular; */
  
  background-image: url("images/2.jpg");
  background-position: 40% 40%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

@media screen and (max-width: 845px) {
  body {
    background-position: 0%, 0%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
