#ResumeContainer {
  margin:auto;
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PDFDocument {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PDFPage {
  box-shadow: 0 0 8px rgba(0, 0, 0, .5);
}

.PDFPageOne {
  margin-bottom: 25px;
}

.PDFPage > canvas {
  max-width: 100%;
  height: auto !important;
}

.react-pdf__message {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  max-width: 24 rem;
  margin-top: 1 rem
}

.react-pdf__message--error,
.react-pdf__message--loading {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 24 rem;
  margin-top: 1 rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 0.375rem;
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}
